import "@bloglovin/vue-component-library/style.css";
import { createPinia } from "pinia";
import { init, axiosInit, AxiosPiniaPlugin, InjectedDataPlugin } from "@bloglovin/vue-framework";
import pages from "@/pages";
import { useGeneralStore } from "@/stores/general-store";
import lightSideTheme from "@/themes/light-side-theme";
import darkSideTheme from "@/themes/dark-side-theme";

import "@/styles/base.scss";
import VCL, { useStyleStore } from "@bloglovin/vue-component-library";

axiosInit(window.blclient.data.JWT, window.blclient.data.links.api);

const initializationFunction = async function(vuePlugins, piniaProps) {
  const piniaInstance = vuePlugins.pinia;
  for (const store of Object.keys(piniaProps)) {
    let piniaStoreFileName = store.replace(/^use/, "").replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
    await import(`@/stores/${piniaStoreFileName}`).then(resolvedStoreFile => {
      const storeInstance = resolvedStoreFile[store](piniaInstance);
      storeInstance.$injectCustomProperties(piniaProps[store]);
    });
  }

  const generalStore = useGeneralStore(piniaInstance);
  const styleStore = useStyleStore(piniaInstance);

  if (generalStore.darkSide) {
    styleStore.setTheme(darkSideTheme);
  } else {
    styleStore.setTheme(lightSideTheme);
  }
  generalStore.setApps(window.blclient.data.apps);

  document.addEventListener("keydown", event => {
    if (event.ctrlKey && event.metaKey && event.key === "d") {
      event.preventDefault();
      generalStore.darkSide = !generalStore.darkSide;
    }
  });
};

const pinia = createPinia();
pinia.use(AxiosPiniaPlugin);
pinia.use(InjectedDataPlugin);

const plugins = {
  pinia: pinia,
  vcl: VCL,
};

init(process.env.NODE_ENV, process.env.PROJECT_NAME, pages, plugins, initializationFunction);
